import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import NotFound from "./not-found/not-found";
import { NotificationsPage } from "./notifications/NotificationsPage";
// @ts-ignore
import GraphSVG from "./assets/img/graph.svg";
import { DeliveryReportsPage } from "@/delivery-reports/DeliveryReportsPage";
import { ProgramManagementPage } from "@/program-management/ProgramManagementPage";
import { EventsPage } from "@/events/EventsPage";
import { CreateNotificationsPage } from "@/notifications/CreateNotificationsPage";
import { UsersPage } from "@/users/UsersPage";
import { OrganizationsPage } from "@/organizations/OrganizationsPage";
import { SitesPage } from "@/sites/SitesPage";
import { LoginPage } from "@/auth/LoginPage";
import { OAuthPage } from "@/auth/OAuthPage";

const FF_DELIVERY_REPORT_ENABLED =
  process.env.REACT_APP_FF_DELIVERY_REPORT === "true";

function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

export const menuNav = [
  {
    text: "Program Management",
    name: "Program Management",
    url: "/program-management",
    icon: () => <img className="mx-2 w-5 h-auto" src={GraphSVG} />,
    disabled: false
  },
  {
    text: "Organizations",
    name: "Organizations",
    url: "/organizations",
    icon: () => <i className="fas fa-users mx-2"></i>,
    disabled: false
  },
  {
    text: "Users",
    name: "Users",
    url: "/users",
    icon: () => <i className="fas fa-circle-user mx-2"></i>,
    disabled: false
  },
  {
    text: "Events",
    name: "Events",
    url: "/events",
    icon: () => <i className="fas fa-calendar mx-2"></i>,
    disabled: false
  },
  {
    text: "Notifications",
    name: "Notifications",
    url: "/notifications",
    icon: () => <i className="fas fa-envelope mx-2"></i>,
    disabled: false
  },
  ...(FF_DELIVERY_REPORT_ENABLED
    ? [
        {
          text: "Delivery Reports",
          name: "Delivery Reports",
          url: "/delivery-reports",
          icon: () => <i className="fas mx-2 fa-list-check"></i>,
          disabled: false
        }
      ]
    : [])
];

/**
 *
 * @param {object} props
 * @param {boolean} [props.isMicrofrontendsEnabled]
 * @param {boolean} [props.isLoginMicrofrontendEnabled]
 * @returns {JSX.Element}
 */
const AppRoutes = () => {
  return (
    <BrowserRouter {...{ basename: process.env.PUBLIC_URL }}>
      <Routes>
        <Route path="/" element={<Redirect to="/organizations" />} />

        {/* {isMicrofrontendsEnabled && isLoginMicrofrontendEnabled ? null : (
          <Route path="/login" element={<LoginPage />} />
        )} */}

        <Route path="/login" element={<LoginPage />} />
        <Route path="/oauth" element={<OAuthPage />} />

        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/:portalUserId" element={<UsersPage />} />
        <Route path="/organizations" element={<OrganizationsPage />} />
        <Route
          path="/organizations/:companyAccountId"
          element={<OrganizationsPage />}
        />
        <Route
          path="/organizations/:companyAccountId/sites/:siteId"
          element={<SitesPage />}
        />

        <Route path="/notifications" element={<NotificationsPage />} />
        <Route
          path="/notifications/create"
          element={<CreateNotificationsPage />}
        />
        <Route path="/program-management" element={<ProgramManagementPage />} />
        <Route path="/events" element={<EventsPage />} />

        {FF_DELIVERY_REPORT_ENABLED && (
          <Route path="/delivery-reports" element={<DeliveryReportsPage />} />
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
