import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

export const logout = () => {
  localStorage.clear();
  window.location.href = document.querySelector("base")?.href || "/";
};

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
});

export const getCognitoUser = () => {
  if (!localStorage.getItem("enpowered_user")) return null;
  const user = JSON.parse(localStorage.getItem("enpowered_user"));
  const cognitoUser =
    userPool.getCurrentUser() ||
    (user
      ? new CognitoUser({
          Username: user.email,
          Pool: userPool
        })
      : null);
  return cognitoUser;
};

export const getCognitoSession = async () => {
  const cognitoUser = getCognitoUser();
  if (!cognitoUser) return null;
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        logout();
      } else resolve(session);
    });
  });
};

export const getIdToken = async () => {
  const session = await getCognitoSession();
  if (!session) return null;
  return session.getIdToken().getJwtToken();
};
