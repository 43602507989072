import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import {
  activateSite,
  assertMultipleProgramEvents,
  assertProgramEvent,
  enumerateMarketDatasets,
  enumerateProgramEvents,
  enumerateSites,
  queryEnergyPrograms,
  configureProgramNotification,
  updateSite,
  enumerateProgramNotifications,
  enumerateRecipientProgramNotifications,
  cancelProgramNotification,
  configureNotificationRecipient,
  enumerateNotificationRecipients,
  deleteNotificationRecipient,
  enumerateOrganizationProgramNotifications,
  enumerateRecipientProgramNotificationsV2,
  enumerateProgramNotificationsV2
} from "../_services";

/**
 *
 * @param {object} params
 * @param {string} [params.programId]
 * @param {boolean | 'ANY'} [params.isActive]
 * @param {string} [params.system]
 * @param {string} [params.region]
 * @param {string} [params.programScope]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.cursor]
 * @returns {import("react-query").UseQueryResult<{items:import("../_services").EnergyProgram[]}, object>}
 */
export const useQueryEnergyPrograms = params =>
  useQuery(["energy-programs", params], () => queryEnergyPrograms(params));

/**
 * Get Energy program by ID
 * @param {string} programId
 * @returns {import("react-query").UseQueryResult<import("../_services").EnergyProgram, object>}
 */
export const useGetEnergyProgram = programId =>
  useQuery(["energy-program", programId], () =>
    queryEnergyPrograms({ programId }).then(({ items }) => items[0] || null)
  );

/**
 *
 * @param {object} params
 * @param {string} [params.id]
 * @param {string} [params.owner]
 * @returns {import("react-query").UseQueryResult<import("../_services").Site[], unknown>}
 */
export const useEnumerateSites = params =>
  useQuery(["sites", params], () => enumerateSites(params));

/**
 *
 * @param {() => void} success
 * @returns {import("react-query").UseMutationResult<object, object>}
 */
export const useActivateSite = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").Site} site
     **/
    site => activateSite(site),
    {
      /** @param {object} props */
      onSuccess: ({ siteId }, site) => {
        queryClient.invalidateQueries(["site", siteId]);
        queryClient.invalidateQueries(["sites", site.siteInfo.ownerId]);

        success && success();
      }
    }
  );
};

/**
 *
 * @param {() => void} success
 * @returns {import("react-query").UseMutationResult<object, object>}
 */
export const useUpdateSite = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").Site} site
     **/
    site => updateSite(site),
    {
      /** @param {object} props */
      onSuccess: ({ siteId }, site) => {
        queryClient.invalidateQueries(["site", siteId]);
        queryClient.invalidateQueries(["sites", site.siteInfo.ownerId]);

        success && success();
      }
    }
  );
};

/**
 *
 * @param {(params: import("../_services").ProgramEvent) => void} success
 * @returns {import("react-query").UseMutationResult<any, any, import("../_services").ProgramEvent, unknown>}
 */
export const useAssertProgramEvent = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").ProgramEvent} programEvent
     **/
    programEvent => assertProgramEvent(programEvent),
    {
      /**
       *
       * @param {{
       *  attributes: import("../_services").ProgramEvent,
       *  modifiedAttributes: import("../_services").ProgramEvent,
       *  programEventId: string
       * }} response
       */
      onSuccess: ({ programEventId, attributes, modifiedAttributes }) => {
        queryClient.invalidateQueries(["program-events"]);

        success &&
          success({ programEventId, ...attributes, ...modifiedAttributes });
      }
    }
  );
};

/**
 *
 * @param {(params: import("../_services").ProgramEvent[]) => void} success
 * @returns {import("react-query").UseMutationResult<any, any, import("../_services").ProgramEvent[], unknown>}
 */
export const useAssertMultipleProgramEvents = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").ProgramEvent[]} programEvents
     **/
    programEvents => assertMultipleProgramEvents(programEvents),
    {
      /**
       *
       * @param {{
       *  attributes: import("../_services").ProgramEvent,
       *  modifiedAttributes: import("../_services").ProgramEvent,
       *  programEventId: string
       * }[]} response
       */
      onSuccess: response => {
        queryClient.invalidateQueries(["program-events"]);

        success &&
          success(
            response.map(
              ({ programEventId, attributes, modifiedAttributes }) => ({
                programEventId,
                ...attributes,
                ...modifiedAttributes
              })
            )
          );
      }
    }
  );
};

/**
 *
 * @param {object} params
 * @param {string} [params.id]
 * @param {string[]} [params.ids]
 * @param {string} [params.eventDate]
 * @param {string} [params.from]
 * @returns {import("react-query").UseQueryResult<import("../_services").ProgramEvent[], object>}
 */
export const useEnumerateProgramEvents = params =>
  useQuery(["program-events", params], async () => {
    if (params.ids) {
      return Promise.all(
        params.ids.map(id =>
          enumerateProgramEvents({ id }).then(({ items }) => items)
        )
      ).then(programEvents => programEvents.flat());
    }

    let cursor = null;
    let programEvents = [];

    do {
      const response = await enumerateProgramEvents(
        cursor ? { cursor } : params
      );
      programEvents = [...programEvents, ...response.items];
      cursor = response.cursor;
    } while (cursor);

    return programEvents;
  });

/**
 *
 * @param {object} params
 * @param {string} params.id
 * @returns {import("react-query").UseQueryResult<{items: import("../_services").MarketDataset[]}, object>}
 */
export const useEnumerateMarketDatasets = params =>
  useQuery(["market-dataset", params.id], () =>
    enumerateMarketDatasets(params)
  );

/**
 *
 * @param {(programNotification: import("../_services").ProgramNotification) => void} success
 */
export const useConfigureProgramNotification = success => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").ProgramNotification} programNotification
     **/
    programNotification => configureProgramNotification(programNotification),
    {
      /**
       *
       * @param {object} response
       * @param {string} response.programNotificationId
       * @param {import("../_services").ProgramNotification} response.attributes
       * @param {import("../_services").ProgramNotification} response.modifiedAttributes
       */
      onSuccess: ({
        programNotificationId,
        attributes,
        modifiedAttributes
      }) => {
        queryClient.invalidateQueries(["program-notifications"]);
        success &&
          success({
            programNotificationId,
            ...attributes,
            ...modifiedAttributes
          });
      }
    }
  );
};

/**
 *
 * @param {object} params
 * @param {string} [params.date]
 * @param {string} [params.cursor]
 * @returns {import("react-query").UseQueryResult<{items: import("../_services").MarketDataset[]}, object>}
 */
export const useEnumerateProgramNotifications = params =>
  useQuery(["program-notifications", params], () =>
    enumerateProgramNotifications(params)
  );

/**
 *
 * @param {object} params
 * @param {string} [params.date]
 * @param {string} [params.cursor]
 * @returns {import("react-query").UseInfiniteQueryResult<{items: import("../_services").ProgramNotification[]; cursor?:string}, any>}
 */
export const useInfiniteProgramNotifications = params =>
  useInfiniteQuery({
    queryKey: ["program-notifications", params],
    queryFn: ({ pageParam }) => {
      return enumerateProgramNotifications(
        pageParam ? { cursor: pageParam } : params
      );
    },
    getNextPageParam: lastPage => {
      // @ts-ignore
      return lastPage?.cursor;
    }
  });

/**
 *
 * @param {import("../_services").RecipientProgramNotificationParams} params
 * @returns {import("react-query").UseInfiniteQueryResult<{items: any[]; cursor?:string}, any>}
 */
export const useInfiniteRecipientProgramNotifications = params =>
  useInfiniteQuery({
    queryKey: ["recipient-program-notifications", params],
    queryFn: ({ pageParam }) => {
      return enumerateRecipientProgramNotifications(
        pageParam ? { cursor: pageParam } : params
      );
    },
    getNextPageParam: lastPage => {
      // @ts-ignore
      return lastPage?.cursor;
    }
  });

/**
 *
 * @param {import("../_services").RecipientProgramNotificationParams} params
 * @returns {import("react-query").UseQueryResult<import("../_services").RecipientProgramNotification[]>}
 */
export const useEnumerateRecipientProgramNotifications = params =>
  useQuery(["recipient-program-notifications", params], async () => {
    let cursor = null;
    let notificationRecipients = [];

    do {
      const response = await enumerateRecipientProgramNotifications(
        cursor ? { cursor } : params
      );
      notificationRecipients = [...notificationRecipients, ...response.items];
      cursor = response.cursor;
    } while (cursor);

    return notificationRecipients;
  });

/**
 *
 * @param {(programNotification: import("../_services").ProgramNotification) => void} success
 * @returns
 */
export const useCancelProgramNotification = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     *
     * @param {{notificationId: string}} params
     * @returns
     */
    params => cancelProgramNotification(params),
    {
      /**
       *
       * @param {object} response
       * @param {string} response.programNotificationId
       * @param {import("../_services").ProgramNotification} response.attributes
       */
      onSuccess: ({ programNotificationId, attributes }) => {
        queryClient.invalidateQueries(["program-notifications"]);
        success &&
          success({
            programNotificationId,
            ...attributes
          });
      }
    }
  );
};

/**
 * @param {(recipient: import("../_services").NotificationRecipient) => void} success
 * @returns {import("react-query").UseMutationResult<import("../_services").NotificationRecipient, unknown, import("../_services").NotificationRecipient, unknown>}
 */
export const useConfigureNotificationRecipient = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").NotificationRecipient} params
     **/
    params => configureNotificationRecipient(params),
    {
      /** @param {object} props */
      onSuccess: ({ recipientId }, params) => {
        queryClient.invalidateQueries(["notification-recipients"]);

        success && success({ ...params, recipientId });
      }
    }
  );
};

/**
 *
 * @param {import("../_services").EnumerateNotificationRecipientsParams} params
 * @returns {import("react-query").UseQueryResult<{items: import("../_services").NotificationRecipient[]}, unknown>}
 */
export const useEnumerateNotificationRecipients = params =>
  useQuery(["notification-recipients", params], () =>
    enumerateNotificationRecipients(params)
  );

/**
 *
 * @param {() => void} success
 * @returns {import("react-query").UseMutationResult<any, unknown, any, unknown>}
 */
export const useDeleteNotificationRecipient = success => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {{recipientId: string}} params
     **/
    params => deleteNotificationRecipient(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notification-recipients"]);

        success && success();
      }
    }
  );
};

/**
 *
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {string} [params.programId]
 * @param {string} [params.date] YYYY-MM-DD
 * @param {string} [params.time] HH:mm
 * @param {string} [params.label]
 * @param {string} [params.status]
 * @param {string} [params.orgName]
 * @param {string} [params.rpnStatus]
 * @param {string} [params.email]
 * @param {string} [params.phoneNumber]
 * @param {string} [params.name]
 * @returns {import("react-query").UseInfiniteQueryResult<{items: import("../_services").DeliveryReportNotification[]; cursor?:string}, any>}
 */
export const useInfiniteProgramNotificationsV2 = params =>
  useInfiniteQuery({
    queryKey: ["program-notifications-v2", params],
    queryFn: ({ pageParam }) => {
      return enumerateProgramNotificationsV2(
        pageParam ? { cursor: pageParam } : params
      );
    },
    getNextPageParam: lastPage => {
      // @ts-ignore
      return lastPage?.cursor;
    }
  });

/**
 *
 * @param {object} params
 * @param {string} params.notificationId
 * @param {string} [params.status]
 * @param {string} [params.rpnStatus]
 * @param {string} [params.orgName]
 * @param {string} [params.email]
 * @param {string} [params.phoneNumber]
 * @param {string} [params.name]
 * @returns {import("react-query").UseQueryResult<import("../_services").OrganizationProgramNotification[], object>}
 */
export const useEnumerateOrganizationProgramNotifications = params =>
  useQuery(["organization-program-notifications", params], async () => {
    const response = await enumerateOrganizationProgramNotifications(params);
    return response?.items || [];
  });

/**
 *
 * @param {object} params
 * @param {string} [params.organizationId]
 * @param {string} [params.programNotificationId]
 * @param {string} [params.name]
 * @param {string} [params.email]
 * @param {string} [params.phoneNumber]
 * @param {string} [params.status]
 * @returns {import("react-query").UseQueryResult<import("../_services").RecipientProgramNotification[], object>}
 */
export const useEnumerateRecipientProgramNotificationsV2 = params =>
  useQuery(["recipient-program-notifications-v2", params], async () => {
    const response = await enumerateRecipientProgramNotificationsV2(params);
    return response?.items || [];
  });
