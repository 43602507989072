import { getIdToken } from "@/_services";
import { handleHttpError } from "./handleHttpError";

export const authedFetch = (uri, params = {}) =>
  getIdToken().then(accessToken =>
    fetch(uri, {
      ...params,
      headers: {
        ...(params?.headers || {}),
        Authorization: `Bearer ${accessToken}`
      }
    }).then(handleHttpError)
  );
