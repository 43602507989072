import { AltLayout, Button, sleep } from "@enpowered/ui";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @ts-ignore
import notFoundGify from "../assets/img/404-whoops.gif";
import { useLogout } from "@/_hooks";

const NotFound = ({ user = null, navItems = [] }) => {
  const [showContent, setShowContent] = useState(false);
  const logout = useLogout();
  const isLoggedIn = !!user;
  const navigate = useNavigate();

  useEffect(() => {
    sleep(2000).then(() => {
      if (!isLoggedIn) {
        navigate("/login");
      } else {
        setShowContent(true);
      }
    });
  }, []);
  return (
    <AltLayout
      pageTitle=""
      logout={logout}
      userName={user?.firstName || null}
      Link={props => <Link {...props} />}
      isUrlActive={() => false}
      items={user ? navItems : []}
    >
      <div className="spin-when-empty">
        {showContent ? (
          <div className="text-center py-8">
            <img
              src={notFoundGify}
              className="inline-block"
              alt="Not Found Animation"
            />

            <div className="py-8">
              {isLoggedIn ? (
                <Button
                  as={Link}
                  // @ts-ignore
                  to="/"
                >
                  Back to Home
                </Button>
              ) : (
                <Button
                  as={Link}
                  // @ts-ignore
                  to="/login"
                >
                  Sign in
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </AltLayout>
  );
};

export default NotFound;
