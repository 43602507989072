import { getIdToken } from "@/_services";
import { handleHttpError } from "@/_utils/handleHttpError";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const ENPOWERED_USER = "enpowered_user";

export const UserContext = React.createContext({
  user: {
    email: null,
    emailVerified: false,
    id: null,
    name: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    phoneNumberVerified: false,
    username: null,
    accessToken: null,
    companyId: null,
    organizations: [],
    organization: null
  },
  setUser: () => {},
  isLoggedIn: false,
  clear: () => {},
  refresh: async () => {},
  initUser: () => Promise.resolve()
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(ENPOWERED_USER) || "null")
  );
  const isLoggedIn = !!user;
  const clear = () => {
    localStorage.removeItem(ENPOWERED_USER);
    setUser(null);
  };
  const refresh = async () => {
    const token = await getIdToken();
    const res = await getMe(token);

    await initUser({
      ...user,
      ...res?.data?.attributes,
      id: res?.data?.id
    });
  };
  const initUser = async (
    { id, email, emailVerified, ...attributes },

    getAccount = () => Promise.resolve({})
  ) => {
    const user = {
      ...attributes,
      id,
      email,
      emailVerified
    };

    // @ts-ignore
    const [organization] = user.organizations || [];

    // @ts-ignore
    const company = organization?.id ? await getAccount(organization?.id) : {};

    // @ts-ignore
    user.organization = {
      ...organization,
      ...company,
      locations:
        // @ts-ignore
        company?.locations?.length &&
        !!process.env.REACT_APP_SHOW_ICI_BY_REGIONS
          ? // @ts-ignore
            company?.locations
          : [
              {
                locationSlug: "region-ontario",
                locationType: "region",
                label: "Ontario"
              }
            ],
      // @ts-ignore
      serviceSubscriptions: company?.serviceSubscriptions || {}
    };
    // @ts-ignore
    user.companyId = organization?.id;
    setUser(user);
  };

  const value = {
    user,
    setUser,
    isLoggedIn,
    clear,
    refresh,
    initUser
  };

  useEffect(() => {
    localStorage.setItem(ENPOWERED_USER, JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (user) {
      refresh();
    }
  }, []);

  // @ts-ignore
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.any
};

const getMe = accessToken =>
  fetch(`${process.env.REACT_APP_API_ROOT}/auth/me`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })
    .then(handleHttpError)
    .then(res => res.json());
